<template>
  <v-container class="courseupcominginner container-custom" v-if="manualPopup">
    <div>
      <div class="formjoin absModal">
        <div class="insideBox">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              background-color: #1976d2;
              padding: 10px;
              border-radius: 10px 10px 0 0;
            "
          >
            <h6 style="font-size: 20px; color: #fff">
              Manual Video Subscription
            </h6>

            <v-icon
              @click="closePopup"
              style="color: #fff; position: relative; top: -2px"
              >mdi-close</v-icon
            >
          </div>

          <v-form
            ref="formUpcoming"
            v-model="valid"
            lazy-validation
            class=""
            style="padding: 20px 20px 20px 20px"
          >
            <!-- Search input -->
            <v-text-field
              v-model="searchQuery"
              outlined
              label="Search users by name, email or phone"
              append-icon="mdi-magnify"
              @input="searchUsers"
              clearable
            ></v-text-field>

            <!-- Loading indicator -->
            <div v-if="loading" class="text-center my-3">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>

            <!-- User list -->
            <v-card v-if="users.length > 0" class="mb-4" outlined>
              <v-list>
                <v-list-item
                  v-for="(user, index) in users"
                  :key="index"
                  @click="selectUser(user)"
                  :class="{ 'selected-user': selectedUser && selectedUser._id === user._id }"
                >
                  <v-list-item-avatar v-if="user.imageUrl">
                    <v-img :src="user.imageUrl"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-avatar>
                  
                  <v-list-item-content>
                    <v-list-item-title>{{ user.fullname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ user.phone }}</v-list-item-subtitle>
                  </v-list-item-content>
                  
                  <v-list-item-action>
                    <div class="d-flex flex-column align-end">
                      <v-chip
                        :color="user.status ? 'success' : 'error'"
                        small
                        text-color="white"
                        class="mb-1"
                      >
                        {{ user.status ? 'Active' : 'Inactive' }}
                      </v-chip>
                      
                      <v-chip
                        :color="user.verified ? 'info' : 'warning'"
                        small
                        text-color="white"
                        class="mb-1"
                      >
                        {{ user.verified ? 'Verified' : 'Unverified' }}
                      </v-chip>
                      
                      <v-chip
                        v-if="user.resume"
                        color="primary"
                        small
                        text-color="white"
                      >
                        Resume
                      </v-chip>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              
              <!-- Pagination -->
              <v-card-actions>
                <v-pagination
                  v-if="pagination.totalPages > 1"
                  v-model="currentPage"
                  :length="pagination.totalPages"
                  @input="changePage"
                  total-visible="5"
                ></v-pagination>
              </v-card-actions>
            </v-card>
            
            <!-- No users found message -->
            <v-alert
              v-else-if="!loading && searchQuery"
              type="info"
              dense
              outlined
            >
              No users found matching your search criteria
            </v-alert>

            <!-- Selected user info -->
            <v-card v-if="selectedUser" class="mb-4 pa-4" outlined>
              <h4 class="subtitle-1 font-weight-bold mb-2">Selected User</h4>
              <p><strong>Name:</strong> {{ selectedUser.fullname }}</p>
              <p><strong>Email:</strong> {{ selectedUser.email }}</p>
              <p><strong>Phone:</strong> {{ selectedUser.phone }}</p>
             
              <p><strong>Account Created:</strong> {{ formatDate(selectedUser.createdAt) }}</p>
            </v-card>

            <v-btn
              type="button"
              :loading="submitLoading"
              :disabled="!selectedUser"
              class="primary"
              @click="submitFormUpcoming"
              >Create Subscription</v-btn
            >
          </v-form>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ContactForm",
  props: ["manualPopup"],
  data: () => ({
    bannerUrl: require("@/assets/placeholder.jpg"),
    dialog: false,
    valid: true,
    fullNameUp: "",
    emailUp: "",
    phoneUp: "",
    submitLoading: false,
    searchQuery: "",
    loading: false,
    selectedUser: null,
    users: [],
    currentPage: 1,
    itemsPerPage: 3,
    pagination: {
      totalItems: 0,
      totalPages: 0
    },
    searchTimeout: null,
  }),
  created() {
    // Initial load of users
    this.fetchUsers();
  },
  methods: {
    ...mapActions(["sendEnquiry", "getUsers"]),
    closePopup() {
      this.$emit("closepopup", false);
    },
    
    // Fetch users based on current filter settings
    async fetchUsers() {
      try {
        this.loading = true;
        
        const params = {
          search: this.searchQuery || "",
          skip: this.currentPage,
          limit: this.itemsPerPage
        };
        
        await this.getUsers(params);
        
        if (this.allUser) {
          // Extract user list from the API response
          this.users = this.allUser.results.list || [];
          
          // Set up pagination based on the response format
          this.pagination = {
            currentPage: this.currentPage,
            totalItems: this.allUser.results.pagination.total || 0,
            totalPages: Math.ceil((this.allUser.results.pagination.total || 0) / this.itemsPerPage),
            nextPage: this.allUser.results.pagination.next
          };
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        this.$toast.error("Failed to load users");
      } finally {
        this.loading = false;
      }
    },
    
    // Handle search input with debounce
    searchUsers() {
      clearTimeout(this.searchTimeout);
      
      this.searchTimeout = setTimeout(() => {
        this.currentPage = 1; // Reset to first page on new search
        this.fetchUsers();
      }, 500); // 500ms debounce
    },
    
    // Handle page change
    changePage() {
      this.fetchUsers();
    },
    
    // Select a user from the list
    selectUser(user) {
      this.selectedUser = user;
      this.fullNameUp = user.fullname;
    },
    
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
      });
    },
    
    // Submit the form with selected user
    async submitFormUpcoming() {
      if (this.$refs.formUpcoming.validate() && this.selectedUser) {
        this.submitLoading = true;
        
        const data = {
          userId: this.selectedUser._id,
          subject: "Video Subscription",
          // Add any other necessary fields
        };
        
        try {
          await this.sendEnquiry(data);
          this.$toast.success("Subscription created successfully");
          this.$refs.formUpcoming.reset();
          this.selectedUser = null;
          this.closePopup();
        } catch (error) {
          console.error("Error creating subscription:", error);
          this.$toast.error("Failed to create subscription");
        } finally {
          this.submitLoading = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["allUser"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.courseupcoming {
  padding: 40px 0;
  .courseupcominginner {
    .btncta {
      background-color: #3773ae;
      display: block;
      text-align: center;
      padding: 15px;
      border-radius: 10px;
      color: #fff;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    .btncta:hover {
      background-color: #fff;
      color: #3773ae;
      border: 2px solid #3773ae;
    }
    .btncta1 {
      background-color: #fff;
      display: block;
      text-align: center;
      padding: 15px;
      border-radius: 10px;
      border: 2px solid #3773ae;
      color: #3773ae;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    .btncta1:hover {
      background-color: #3773ae;
      color: #fff;
    }

    .subtitle {
      padding-top: 20px;

      h4 {
        font-size: 30px;
        padding: 0;
      }

      p {
        text-align: center;
        width: 60%;
        margin: 0 auto;
        padding: 15px 0;
      }

      .btncta2 {
        margin: 0 auto;
        background-color: transparent;
        display: block;
        width: 30%;
        text-align: center;
        padding: 15px 0;
        border-radius: 10px;
        border: 1px solid #3773ae;
        color: #3773ae;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.6px;
      }

      .btncta2:hover {
        background-color: #1876d2;
        color: #fff;
      }
    }

    .coursecard {
      border: 1px solid #ff9f00;
      border-radius: 10px;
      width: 600px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      gap: 30px;
      align-items: start;

      .datee {
        h6 {
          line-height: 22px;
          font-size: 32px;
          text-align: center;
          color: #ff9f00;
        }

        span {
          &:nth-child(2) {
            font-size: 18px;
            font-weight: normal;
          }
        }
      }

      .coursedetails {
        h5 {
          color: #363636;
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          text-transform: uppercase;
          margin: 0 auto;
        }

        p {
          line-height: 26px;
        }

        ul {
          line-height: 26px;

          li {
            padding: 0px;
            display: block;
            margin: 0;
          }
        }
      }
    }
  }
}
.courseupcoming {
  padding: 80px 0;

  .courseupcominginner {
    width: 100%;

    h4 {
      color: #363636;
      font-size: 36px;
      font-weight: bold;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      text-align: center;
      text-transform: uppercase;
      margin: 0 auto;
      line-height: 44px;
    }
  }
}

.absModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.insideBox {
  background: #fff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
}
.paymentList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  li {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .active {
    border: 1px solid #1876d2;
  }
}

.selected-user {
  background-color: rgba(25, 118, 210, 0.1);
}

@media screen and (max-width: 600px) {
  .insideBox {
    width: 95%;
  }
  .coursecard {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .btncta,
  .btncta1 {
    font-size: 12px !important;
  }
}
</style>